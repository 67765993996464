import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import * as React from 'react';
import Button from '../Components/CustomButtons/Button';
import GridContainer from '../Components/Grid/GridContainer';
import GridItem from '../Components/Grid/GridItem';
import { LoginContext } from '../contexts/LoginContext';
import { TestContext } from '../contexts/TestContext';

export default function TableOfContaints() {
    const { testAttemptDetails, test, setCurrentQuestionIndex } = React.useContext(TestContext);
    const { showToC, setShowTOC } = React.useContext(LoginContext);
    const [questions, setQuestions] = React.useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (questions.length == 0) {
        for (let j = 0; j < test.pools["$values"].length; j++) {
            for (let i = 0; i < test.pools["$values"][j].questions["$values"].length; i++) {
                setQuestions(arr => [...arr, test.pools["$values"][j].questions["$values"][i]])
            }
        }
    }

    const handleclick = (i) => {
        setCurrentQuestionIndex(i);
        if (isMobile) {
            setShowTOC(!showToC)
        }
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    return (
        <div style={{ height: '446px', marginTop: '1rem', marginBottom: '4rem', padding: '20px 6px 20px 15px', overflowY: 'auto', overflowX: 'hidden' }}>
            <GridContainer >
                {Object.values(questions).map((x, i) => (
                    <GridItem key={i} xs={3} sm={3} md={3} lg={3} style={{ textAlign: 'center', padding: '0px !important' }}>
                        <Button
                            justIcon
                            round
                            color={x.markForReview ? 'primary' : !isEmpty(x.response) ? 'success' : 'white'}
                            onClick={() => handleclick(i)}
                            disabled={testAttemptDetails.isSubmited == true || testAttemptDetails.currentPageIndex != 1}
                        >
                            {i + 1}
                        </Button>
                    </GridItem>
                ))}
            </GridContainer>
        </div>

    );
}