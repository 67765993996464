import {
  Assignment,
  BorderColor,
  BorderColorOutlined,
  DnsOutlined,
  EmojiEventsOutlined,
  HomeOutlined,
  ListAltOutlined,
  NotificationsActiveOutlined,
  PortraitOutlined,
  Settings,
  SupervisorAccountOutlined,
} from "@material-ui/icons";
import {
  Logout,
  LogoutOutlined,
  ManageAccountsOutlined,
  QuizOutlined,
} from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, ListItemButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import ChangePassword from "./ChangePassword";
import ParentDetails from "./ParentDetails";
import PersonalDetails from "./PersonalDetails";
import TestViewer from "./TestViewer";
// import Chart from './Chart';
import { makeStyles } from "@material-ui/core";
import StudentDashboard from "../Dashboard/StudentDashboard";
import styles from "../styles/HomeScreen.module.css";
import CustomModal from "./CustomModal/CustomModal";
import Notification from "./HomeMenuComponents/Notification";
import SwitchProvider from "./SwitchProvider";
import TestSeries from "./TestSeries";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Splayndid Technology
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "#5F5C57",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  },
}));

const mdTheme = createTheme();

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1.5em",
  },
});
function DashboardContent() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setUserName,
    userDetail,
    UserName,
    emailC,
    setProviderId,
    providerId,
    setUserId,
    providerName,
    providerKey,
    setUserType,
    pageKey,
    setPageKey,
    setStatus,
    setAssessmet,
    status,
    providerList,
    userPrivileges,
  } = React.useContext(LoginContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openlist, setOpenList] = React.useState(false);
  const [openAssessment, setOpenAssessment] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [showModel, setShowModel] = React.useState(null);

  // const result = GetProvider();
  // console.log("BalajiSSS", result?.data);
  console.log("name", providerName);
  console.log("prkey", providerKey);

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isPrivilege = (x) => {
    if (userPrivileges?.privileges?.learnerSite?.$values?.includes(x)) {
      return true;
    } else {
      return false;
    }
  };
  const toggleDrawer = () => {
    setOpen(!open);
    if (window.innerWidth < 768) {
      setExpanded(!expanded);
    }
  };
  const handleClick = () => {
    setOpenList(!openlist);
  };
  const handleAssessmentClick = () => {
    setOpenAssessment(!openAssessment);
  };
  const handleContaint = (value) => {
    console.log(providerKey, "providerkey from screen at logout");
    setStatus("ALL");
    setAssessmet(true);
    setPageKey(value);
    console.log("initialNavigate", pageKey, status);
    if (value === 0) {
      sessionStorage.clear();
      sessionStorage.setItem("login", false);
      navigate(`/${providerKey}`);
    }
    toggleDrawer();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openProfile = Boolean(anchorEl);
  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onOpenMobileNav = () => {
    setOpen(!open);
    setExpanded(!expanded);
    // toggleDrawer()
  };
  console.log(open, "openval");
  console.log(expanded, "expandedval");
  const showProviderList = async (providerlist) => {
    console.log(providerlist, "providerlists");
    setShowModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={<SwitchProvider providerlist={providerlist} />}
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={t("selectProvider")}
        // maxWidth="lg"
        hideTitle={true}
      // fullWidth={false}
      />
    );
  };
  const hideEvaluationModel = (event, reason) => {
    console.log("reason", reason);
    if (reason === "backdropClick") {
      return;
    }
    setShowModel(null);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          style={{ background: "#ffffff" }}
        >
          {/* new Custom modal when tests are not available */}

          {/* <NewCustomModal/>  */}
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="Black"
              aria-label="open drawer"
              className={styles.webButton}
              onClick={() => onOpenMobileNav()}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={"Menu"}
                placement="right"
              >
                <MenuIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              edge="start"
              color="Black"
              aria-label="open drawer"
              className={styles.mobileButton}
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={"Menu"}
                placement="right"
              >
                <MenuIcon />
              </Tooltip>
            </IconButton>
            {/* provider name */}
            <Typography
              component="h1"
              variant="h5"
              color="#FF802B"
              noWrap
              sx={{ flexGrow: 1, textAlign: "start" }}
            >
              {providerName ?? ""}
            </Typography>
            {/* SearchBar */}

            {/* <Paper
                  className={styles.searchBar}
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 416 }}
                >
                
                  <InputBase
                    sx={{ ml: 1, flex: 1,fontSize:'initial' }}
                    placeholder="Search"
                    
                  />
                  <IconButton type="button" aria-label="search">
                    <SearchIcon className={styles.searchIcon} />
                  </IconButton>
                </Paper> */}

            {/* <IconButton color="Black" onClick={handleClickProfile} aria-controls={openProfile ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? 'true' : undefined} >
              <img className={styles.profile} src="Profile.jpg" alt="profileimg" /><br />
              {UserName}
            </IconButton> */}
            {/* Notification */}
            {isPrivilege("Notification") == true ?
              <IconButton color="Black" onClick={() => handleContaint(2)}>
                <button className={styles.logoutBtn}

                >
                  <NotificationsIcon />
                </button>
                <br />
                {/* 
              {t("notification")} */}
              </IconButton>
              : ""
            }

            <IconButton
              color="Black"
              onClick={() => {
                handleContaint(0);
              }}
            >
              {/* <Badge badgeContent={4} color="secondary"> */}

              {/* <NotificationsIcon /> */}
              <button className={styles.logoutBtn}>
                <LogoutIcon />
              </button>
              <br />
              {/* 
              {t("logOut")} */}
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*onClick Profile menu  */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openProfile}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> {t("profile")}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <BorderColor fontSize="small" />
            </ListItemIcon>
            {t("changePass")}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("settings")}
          </MenuItem>
          <MenuItem onClick={() => handleContaint(0)}>
            <ListItemIcon>
              <Logout style={{ color: "red" }} fontSize="small" />
            </ListItemIcon>
            {t("logOut")}
          </MenuItem>
        </Menu>

        {/* drawer */}
        <Drawer
          variant="permanent"
          style={expanded == false ? { display: "none" } : {}}
          open={open}
          className={styles.sidebarDashboard}
        >
          <Toolbar className={styles.profileToolbar}>
            <img src="Profile.jpg" alt="profileimg" /> {userDetail?.fullName}{" "}
            <br />
            {/* {emailC} */}
            <IconButton className={styles.iconButton} onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {/* dashboard list */}
          <List className={styles.drawerList}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("home")}
              placement="right"
            >
              <ListItemButton
                className={styles.listItemButton}
                onClick={() => handleContaint(1)}
              >
                <HomeOutlined className={styles.drawerListIcon} /> {t("home")}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("notification")}
              placement="right"
            >
              <ListItemButton
                className={styles.listItemButton}
                onClick={() => handleContaint(2)}
                disabled={isPrivilege("Notification") == true ? false : true}
              >
                <NotificationsActiveOutlined
                  className={styles.drawerListIcon}
                />{" "}
                {t("notification")}
              </ListItemButton>
            </Tooltip>

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("profileUpdate")}
              placement="right"
            >
              <ListItemButton
                className={styles.listItemButton}
                onClick={handleClick}
                disabled={isPrivilege("Profile") == true ? false : true}
              >
                <PortraitOutlined className={styles.drawerListIcon} />{" "}
                {t("profileUpdate")}
                {openlist ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Tooltip>
            <Collapse in={openlist} timeout="auto" unmountOnExit>
              <List
                component="div"
                style={{ marginLeft: "10px" }}
                disablePadding
              >
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("personal/academic")}
                  placement="right"
                >
                  <ListItemButton onClick={() => handleContaint(3)}>
                    <ManageAccountsOutlined className={styles.drawerListIcon} />
                    {t("personal/academic")}
                  </ListItemButton>
                </Tooltip>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("parent/sibling")}
                  placement="right"
                >
                  <ListItemButton onClick={() => handleContaint(4)}>
                    <SupervisorAccountOutlined
                      className={styles.drawerListIcon}
                    />
                    {t("parent/sibling")}
                  </ListItemButton>
                </Tooltip>
              </List>
            </Collapse>

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("quiz")}
              placement="right"
            >
              <ListItemButton className={styles.listItemButton} disabled>
                <QuizOutlined className={styles.drawerListIcon} /> {t("quiz")}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("myRewards")}
              placement="right"
            >
              <ListItemButton className={styles.listItemButton} disabled>
                <EmojiEventsOutlined className={styles.drawerListIcon} />{" "}
                {t("myRewards")}
              </ListItemButton>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("changePass")}
              placement="right"
            >
              <ListItemButton
                onClick={() => handleContaint(7)}
                className={styles.listItemButton}
                disabled={isPrivilege("ChangePassword") == true ? false : true}
              >
                <BorderColorOutlined className={styles.drawerListIcon} />{" "}
                {t("changePass")}
              </ListItemButton>
            </Tooltip>
            {/* Switch Provider BSS121 */}
            {/* <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("selectProvider")}
              placement="right"
            >
              <ListItemButton onClick={() => showProviderList(providerList)} className={styles.listItemButton}>
                <SwitchAccountIcon className={styles.drawerListIcon} />{" "}
                {t("selectProvider")}
              </ListItemButton>
            </Tooltip> */}

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("assignments")}
              placement="right"
            >
              <ListItemButton
                className={styles.listItemButton}
                onClick={handleAssessmentClick}
              >
                <ListAltOutlined className={styles.drawerListIcon} />{" "}
                {t("assignments")}
                {openAssessment ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Tooltip>

            <Collapse in={openAssessment} timeout="auto" unmountOnExit>
              <List
                component="div"
                style={{ marginLeft: "10px" }}
                disablePadding
              >
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("assignedTestSeries")}
                  placement="right"
                >
                  <ListItemButton onClick={() => handleContaint(6)}>
                    <DnsOutlined className={styles.drawerListIcon} />
                    {t("assignedTestSeries")}
                  </ListItemButton>
                </Tooltip>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("assignedTests")}
                  placement="right"
                >
                  <ListItemButton onClick={() => handleContaint(5)}>
                    <Assignment className={styles.drawerListIcon} />
                    {t("assignedTests")}
                  </ListItemButton>
                </Tooltip>
              </List>
            </Collapse>

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("logOut")}
              placement="right"
            >
              <ListItemButton
                className={styles.listItemButton}
                style={{ color: "red" }}
                onClick={() => handleContaint(0)}
              >
                <LogoutOutlined className={styles.drawerListIcon} />{" "}
                {t("logOut")}
              </ListItemButton>
            </Tooltip>
          </List>
        </Drawer>
        {/* dashboard screens pages */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            background: "#EFD7AF80",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {pageKey === 1 ? <StudentDashboard /> : ""}
            {pageKey === 2 ? <Notification /> : ""}
            {pageKey === 3 ? <PersonalDetails /> : ""}
            {pageKey === 4 ? <ParentDetails /> : ""}
            {pageKey === 5 ? <TestViewer /> : ""}
            {pageKey === 6 ? <TestSeries /> : ""}
            {pageKey === 7 ? <ChangePassword /> : ""}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      {showModel}
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
